import { InertiaLinkProps, Link } from "@inertiajs/react";
import { Button, ButtonProps } from "./button";

interface Props extends InertiaLinkProps {
  buttonProps?: Omit<ButtonProps, "children" | "asChild">;
}

export const InertiaLink = ({ children, buttonProps, ...linkProps }: Props) => {
  return (
    <Button asChild {...buttonProps}>
      <Link {...linkProps}>{children}</Link>
    </Button>
  );
};

import { circleGrowViewTransition } from "@/lib/utils";
import { THEME_DEFAULT_CONFIG } from "@/tailwind/theme-default-config";
import { Theme, ThemeRadius, ThemeVariant } from "@/tailwind/themes";
import { createContext, useCallback, useEffect, useState } from "react";

interface ThemeProviderProps {
  children: React.ReactNode;
  defaultTheme?: Theme;
  defaultThemeVariant?: ThemeVariant;
  defaultThemeRadius?: ThemeRadius;
  storageKey?: string;
  themeVariantStorageKey?: string;
  themeRadiusStorageKey?: string;
}

interface ThemeProviderState {
  theme: Theme;
  themeVariant: ThemeVariant;
  themeRadius: ThemeRadius;
  setTheme: (theme: Theme) => void;
  setThemeVariant: (themeVariant: ThemeVariant) => void;
  setThemeRadius: (themeRadius: ThemeRadius) => void;
  resetToDefault: () => void;
}

const initialState: ThemeProviderState = {
  theme: THEME_DEFAULT_CONFIG.theme,
  setTheme: () => null,
  themeVariant: THEME_DEFAULT_CONFIG.themeVariant,
  setThemeVariant: () => null,
  themeRadius: THEME_DEFAULT_CONFIG.themeRadius,
  setThemeRadius: () => null,
  resetToDefault: () => null,
};

export const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({
  children,
  defaultTheme = THEME_DEFAULT_CONFIG.theme,
  defaultThemeVariant = THEME_DEFAULT_CONFIG.themeVariant,
  defaultThemeRadius = THEME_DEFAULT_CONFIG.themeRadius,
  storageKey = THEME_DEFAULT_CONFIG.themeStorageKey,
  themeVariantStorageKey = THEME_DEFAULT_CONFIG.themeVariantStorageKey,
  themeRadiusStorageKey = THEME_DEFAULT_CONFIG.themeRadiusStorageKey,
  ...props
}: ThemeProviderProps) {
  const [theme, setTheme] = useState<Theme>(
    () => (localStorage.getItem(storageKey) as Theme) || defaultTheme,
  );
  const [themeVariant, setThemeVariant] = useState<ThemeVariant>(
    () => (localStorage.getItem(themeVariantStorageKey) as ThemeVariant) || defaultThemeVariant,
  );
  const [themeRadius, setThemeRadius] = useState<ThemeRadius>(
    () => (localStorage.getItem(themeRadiusStorageKey) as ThemeRadius) || defaultThemeRadius,
  );

  const resetToDefault = useCallback(() => {
    setTheme(defaultTheme);
    setThemeVariant(defaultThemeVariant);
    setThemeRadius(defaultThemeRadius);
  }, [defaultTheme, defaultThemeRadius, defaultThemeVariant]);

  useEffect(() => {
    const root = window.document.documentElement;
    const media = window.matchMedia("(prefers-color-scheme: dark)");

    root.classList.remove("light", "dark");

    // Function to handle the change in media query
    function onMediaChange() {
      circleGrowViewTransition(root, () => {
        const systemTheme = media.matches ? "dark" : "light";
        root.classList.remove("light", "dark");
        root.classList.add(systemTheme);
      }).catch(console.error);
    }

    if (theme === "system") {
      root.classList.add(media.matches ? "dark" : "light");
      localStorage.setItem(storageKey, theme);

      // listen for changes in the media query
      media.addEventListener("change", onMediaChange);
    } else {
      root.classList.add(theme);
      localStorage.setItem(storageKey, theme);
    }

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      media.removeEventListener("change", onMediaChange);
    };
  }, [storageKey, theme]);

  // Update theme variant data attribute on the root element
  useEffect(() => {
    const root = window.document.documentElement;

    localStorage.setItem(themeVariantStorageKey, themeVariant);
    root.dataset[themeVariantStorageKey] = themeVariant;
  }, [themeVariant, themeVariantStorageKey]);

  // Update theme radius data attribute on the root element
  useEffect(() => {
    const root = window.document.documentElement;

    localStorage.setItem(themeRadiusStorageKey, themeRadius);
    root.dataset[themeRadiusStorageKey] = themeRadius;
  }, [themeRadius, themeRadiusStorageKey]);

  const value = {
    theme,
    themeVariant,
    themeRadius,
    resetToDefault,
    setThemeRadius,
    setThemeVariant,
    setTheme,
  };

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
}

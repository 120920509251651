import Layout from "@/pages/layouts/main-layout";
import { createInertiaApp } from "@inertiajs/react";
import { createElement } from "react";
import { createRoot } from "react-dom/client";

createInertiaApp({
  // Set default page title
  // see https://inertia-rails.netlify.app/guide/title-and-meta
  //
  title: (title) => (title ? `${title} - PaintPal` : "PaintPal"),

  // Disable progress bar
  //
  // see https://inertia-rails.netlify.app/guide/progress-indicators
  // progress: false,

  resolve: (name) => {
    const pages = import.meta.glob("../pages/**/*.tsx", { eager: true });
    // return pages[`../pages/${name}.tsx`];

    // To use a default layout, import the Layout component
    // and use the following lines.
    // see https://inertia-rails.netlify.app/guide/pages#default-layouts

    const page = pages[`../pages/${name}.tsx`];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
    page.default.layout ||= (page) => createElement(Layout, null, page);
    return page;
  },

  setup({ el, App, props }) {
    const root = createRoot(el);

    root.render(createElement(App, props));
  },
}).catch((e) => {
  console.error(e);
  alert(
    "An error occurred while loading the page. Please refresh the page. Probably the page was not found, check controller and components",
  );
});

import { Check, MoonStar, Paintbrush, Sun, Undo2 } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { Label } from "@/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { circleGrowViewTransition, cn } from "@/lib/utils";

import { Theme, THEME_RADIUSES, THEME_VARIANTS, themes, ThemeVariant } from "@/tailwind/themes";
import { useTheme } from "./use-theme";

function generateVariantStyle(variant: ThemeVariant, theme: Theme) {
  return {
    "--theme-primary": `hsl(${themes[variant][theme === "dark" ? ".dark" : ":root"]["--primary"]})`,
  } as React.CSSProperties;
}

export function ThemeCustomizer() {
  const { resetToDefault } = useTheme();

  const resetThemeWithAnimation = (e: React.MouseEvent<HTMLButtonElement>) => {
    circleGrowViewTransition(e.currentTarget, () => {
      resetToDefault();
    }).catch(console.error);
  };

  return (
    <div className="flex items-center">
      {/* Drawer for mobile */}
      <Drawer>
        <DrawerTrigger asChild>
          <Button variant="outline" className="md:hidden">
            <Paintbrush className="mr-2 size-4" />
            Customize
          </Button>
        </DrawerTrigger>
        <DrawerContent className="p-6 pt-0">
          <div className="flex items-start">
            <div className="flex flex-col">
              <DrawerTitle>Customize</DrawerTitle>
              <DrawerDescription>Customize</DrawerDescription>
            </div>
            <Button
              variant="ghost"
              size="icon"
              className="ml-auto rounded-[0.5rem]"
              onClick={resetThemeWithAnimation}
            >
              <Undo2 />
              <span className="sr-only">Reset</span>
            </Button>
          </div>
          <Customizer />
        </DrawerContent>
      </Drawer>

      {/* Popover for md+ */}
      <div className="hidden md:flex">
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="ghost">
              <Paintbrush className="mr-2 size-4" />
              Customize
            </Button>
          </PopoverTrigger>
          <PopoverContent align="center" className="z-40 w-[340px]">
            <Customizer />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}

function Customizer() {
  const {
    theme,
    themeVariant,
    themeRadius,
    setTheme,
    setThemeVariant,
    setThemeRadius,
    resetToDefault,
  } = useTheme();

  const changeThemeWithAnimation = (e: React.MouseEvent<HTMLButtonElement>, theme: Theme) => {
    circleGrowViewTransition(e.currentTarget, () => {
      setTheme(theme);
    }).catch(console.error);
  };

  const changeThemeVariantWithAnimation = (
    e: React.MouseEvent<HTMLButtonElement>,
    themeVariant: ThemeVariant,
  ) => {
    circleGrowViewTransition(e.currentTarget, () => {
      setThemeVariant(themeVariant);
    }).catch(console.error);
  };

  const resetThemeWithAnimation = (e: React.MouseEvent<HTMLButtonElement>) => {
    circleGrowViewTransition(e.currentTarget, () => {
      resetToDefault();
    }).catch(console.error);
  };

  return (
    <div className="flex flex-col space-y-4 md:space-y-6">
      <div className="hidden items-start pt-4 md:flex md:pt-0">
        <div className="space-y-1 pr-2">
          <div className="font-semibold leading-none tracking-tight">Customize</div>
          <div className="text-xs text-muted-foreground">
            Pick a style and color for your components.
          </div>
        </div>
        <Button
          variant="ghost"
          size="icon"
          className="ml-auto rounded-[0.5rem]"
          onClick={resetThemeWithAnimation}
        >
          <Undo2 />
          <span className="sr-only">Reset</span>
        </Button>
      </div>
      <div className="flex flex-1 flex-col space-y-4 md:space-y-6">
        <div className="space-y-1.5">
          <Label className="text-xs">Color</Label>
          <div className="grid grid-cols-3 gap-2">
            {THEME_VARIANTS.map((variant) => {
              const isActive = themeVariant === variant;

              return (
                <Button
                  variant={"outline"}
                  size="sm"
                  key={variant}
                  onClick={(e) => changeThemeVariantWithAnimation(e, variant)}
                  className={cn("justify-start capitalize", isActive && "border-2 border-primary")}
                  style={generateVariantStyle(variant, theme)}
                >
                  <span
                    className={cn(
                      "mr-1 flex size-5 shrink-0 -translate-x-1 items-center justify-center rounded-full bg-[--theme-primary]",
                    )}
                  >
                    {isActive && <Check className="size-4 text-white" />}
                  </span>
                  {variant}
                </Button>
              );
            })}
          </div>
        </div>
        <div className="space-y-1.5">
          <Label className="text-xs">Radius</Label>
          <div className="grid grid-cols-5 gap-2">
            {THEME_RADIUSES.map((value) => {
              return (
                <Button
                  variant={"outline"}
                  size="sm"
                  key={value}
                  onClick={() => {
                    setThemeRadius(value);
                  }}
                  className={cn(themeRadius === value && "border-2 border-primary")}
                >
                  {value}
                </Button>
              );
            })}
          </div>
        </div>
        <div className="space-y-1.5">
          <Label className="text-xs">Mode</Label>
          <div className="grid grid-cols-3 gap-2">
            <Button
              variant={"outline"}
              size="sm"
              onClick={(e) => changeThemeWithAnimation(e, "light")}
              className={cn(theme === "light" && "border-2 border-primary")}
            >
              <Sun className="mr-2" />
              Light
            </Button>
            <Button
              variant={"outline"}
              size="sm"
              onClick={(e) => changeThemeWithAnimation(e, "dark")}
              className={cn(theme === "dark" && "border-2 border-primary")}
            >
              <MoonStar className="mr-2" />
              Dark
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Moon, Sun, SunMoon } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { circleGrowViewTransition, cn } from "@/lib/utils";
import { Theme, THEMES } from "@/tailwind/themes";
import { createElement } from "react";
import { useTheme } from "./use-theme";

const icons = {
  light: Sun,
  dark: Moon,
  system: SunMoon,
};

export function ThemeSwitcher() {
  const { setTheme, theme } = useTheme();

  const changeTheme = (e: React.MouseEvent<HTMLDivElement>, t: Theme) => {
    circleGrowViewTransition(e.currentTarget, () => {
      setTheme(t);
    }).catch(console.error);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          {Object.entries(icons).map(([key, icon]) =>
            createElement(icon, {
              key: `theme-icon-${key}`,
              className: cn(
                "absolute scale-0 transition-all duration-500",
                theme === key && "scale-100",
              ),
            }),
          )}

          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end">
        {THEMES.map((t) => (
          <DropdownMenuItem
            className="capitalize"
            key={`theme-select-${t}`}
            onClick={(e) => changeTheme(e, t)}
          >
            <div className="flex items-center gap-2">
              {createElement(icons[t], { className: "size-5" })}
              {t}
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
